import React, { useState, useEffect } from 'react'
import {
  RoomStatus,
  InfoLink,
  DisabledLink,
  RoomSlots,
  Dot
} from './Styled'

import axios from 'axios'

Object.size = function(obj) {
  var size = 0, key
  for (key in obj) {
      if (obj.hasOwnProperty(key)) size++
  }
  return size
}

export default function InfoRoom({meeting}) {

  const [count,setCount] = useState(-1)
  
  useEffect(() => {
    async function fetchData(){
      const request = await axios.get(`https://superviz-a4b56.firebaseio.com/SuperViz/production/${meeting}/people.json`)
      setCount(request.data === null ? 0 : Object.size(request.data))
      console.log(meeting, request.data)
    }
    setTimeout( ()=> fetchData(), 300)
    const interval = setInterval(() => {
      fetchData()
    }, 3000);
    return () => clearInterval(interval);
  }, [])

  return (
    <>
      <RoomSlots>
        {[...Array(8).keys()].map(n => n < count ? <Dot key={n} /> : <Dot key={n} available/>)}
      </RoomSlots>
      <RoomStatus
        key={count}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {count === -1 ? `Carregando...` : `${count} de 8 pessoas nesta sala`}
      </RoomStatus>
      {count === -1
        ? <DisabledLink href={`#`} disabled>Entrar na sala</DisabledLink>
        : count >= 8
          ? <DisabledLink href={`#`} disabled>Sala Lotada</DisabledLink>
          : <InfoLink href={`https://go.superviz.com/${meeting}`}>Entrar na sala</InfoLink>
      }
    </>
  )
}
