export const salas = [
  {
    title: `FORCINE 20 anos`,
    text: `O Cinema como Linguagem\u00A0Transversal`,
    meeting: `DSNBJQ`,
    image: `/images/360-forcine20.jpg`
  },
  {
    title: `Viva Ouro Preto`,
    text: `Performance musical e visual imersiva. Projeto\u00A0Bossa\u00A0Criativa - FUNARTE/UFRJ (PPGMC/PROMUS/LAMCE)`,
    meeting: `6KTMXG`,
    image: `/images/360-ouropreto.jpg`
  },
  {
    title: `Cine Metro`,
    text: `Projeto de mestrado de Eduardo\u00A0Calvet, PPGMC/ECO/UFRJ`,
    meeting: `TWYN1B`,
    image: `/images/360-cinemetro.jpg`
  },
  {
    title: `Cine Odeon`,
    text: `Cena do projeto PELA MEMÓRIA DO CINEMA DE RUA, de Creuza\u00A0Gravina, PPGMC/ECO/UFRJ`,
    meeting: `W1XNKB`,
    image: `/images/360-odeon.jpg`
  },
  {
    title: `SOS Cinemateca`,
    text: `Projeto de Creuza\u00A0Gravina, PPGMC/ECO/UFRJ`,
    meeting: `WAER7Y`,
    image: `/images/360-cinemateca.jpg`
  },
  {
    title: `Nelson Pereira dos\u00A0Santos`,
    text: `Uma produção que atravessa 60 anos de história do Brasil`,
    meeting: `JJCE1R`,
    image: `/images/360-nelson.jpg`
  },
  {
    title: `Museu da Geodiversidade`,
    text: `Parceria PPGMC e IGEO no iDoc “O\u00A0QUE\u00A0A\u00A0BAÍA\u00A0TEM?”`,
    meeting: `4CNNOO`,
    image: `/images/360-museu-geo.jpg`
  },
  {
    title: `Psicopompo\u00A0HQ`,
    text: `História em quadrinhos de Octávio\u00A0Aragão e Carlos\u00A0Hollanda`,
    meeting: `L2XQW2`,
    image: `/images/360-psicopompo.jpg`
  },
  {
    title: `História: Antes\u00A0e\u00A0Depois`,
    text: `Comparando o Street View com fotos históricas, de SuperViz`,
    meeting: `D5UND8`,
    image: `/images/360-historia.jpg`
  },
  {
    title: `Cores`,
    text: `Salas Superviz`,
    meeting: `I1MC4S`,
    image: `/images/360-cores.jpg`
  },
  {
    title: `Planos`,
    text: `Salas Superviz`,
    meeting: `AIVC8N`,
    image: `/images/360-matrix.jpg`
  },
  {
    title: `Escola de Comunicação`,
    text: `ECO/UFRJ`,
    meeting: `V0S5HX`,
    image: `/images/360-ecoufrj.jpg`
  }
]