import React from 'react'
import {Main, Header, Footer, ExtLink} from './components/Styled'
import Interface from './components/Interface'

function App() {

  return (
    <Main>
      <Header>
        <ExtLink href="http://www.forcine.org.br/site/">
          <img src="./images/forcine.png" alt="" height="80" />
        </ExtLink>
      </Header>
      <Interface />
      <Footer>
        Powered by
        &nbsp;
        <ExtLink href="https://www.superviz.com/">
          <img src="./images/superviz.svg" alt="" height="16" />
        </ExtLink>
      </Footer>
    </Main>
  );
}

export default App
