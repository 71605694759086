import React, { useState } from 'react'
import { motion } from "framer-motion"
import useWindowSize from '../util/useWindowSize'
import useFrame from '../util/useFrame'
import {
  Content,
  InsideContent,
  CallToAction,
  InfoTitle,
  InfoText,
} from './Styled'
import InfoRoom from './InfoRoom'
import Bolinha from './Bolinha'
import {salas} from '../store'

function Interface() {

  const [selected, setSelected] = useState(null)
  const toggleSelect = (i) => selected === i ? setSelected(null) : setSelected(i)
  
  const count = useFrame() * 0.0001 % 360
 
  const RAD = 0.0174533
  const len = salas.length

  const {width,height} = useWindowSize()
  const h = Math.max(height,640)
  const size = width < height ? Math.min(width,h-200) : h - 200
  const angle = 360*RAD/len
  const x = (i) => size*0.5+Math.sin(i*angle)*(size*0.4)
  const y = (i) => size*0.5+Math.cos(i*angle)*(size*0.4)

  const a = x(1) - x(2)
  const b = y(1) - y(2)
  const d = Math.sqrt(a*a+b*b)

  return (
    <>
    {width &&
      <Content size={size}>
        <div style={{position:`absolute`}}>
        {salas.map((sala, i) => {
          return (
            <Bolinha
              key={i}
              index={i}
              size={d-10}
              x={x(i+count)}
              y={y(i+count)}
              img={sala.image}
              title={sala.title}
              selected={selected===i}
              onClick={()=>toggleSelect(i)}
            />
          )
        })}
        </div>
        <InsideContent padding={d}>
          {selected === null &&
            <CallToAction
              initial={{ scale: 0.5}}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              {`selecione uma sala\u00A0imersiva`}
            </CallToAction>
          }
          {selected !== null &&
            <motion.div
              key={selected}
              initial={{ opacity: 0, scale: width > 768 ? 0.8 : 0.7 }}
              animate={{ opacity: 1, scale: width > 768 ? 0.9 : 0.8 }}
              transition={{ duration: 0.5 }}
            >
              <InfoTitle>{salas[selected].title}</InfoTitle>
              <InfoText>{salas[selected].text}</InfoText>
              {salas[selected].meeting && <InfoRoom meeting={salas[selected].meeting} />}
            </motion.div>
          }
        </InsideContent>
      </Content>
    }
    </>
  )
}

export default Interface
