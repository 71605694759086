import React from 'react'
import { motion } from "framer-motion"
import styled from "styled-components"

const OutCircle = styled.g`
  @media(min-width: 768px) {
    cursor: pointer;
  }
  .icon {
    stroke: ${props => props.selected ? `#feec04`: `none`};
    stroke-width:${props => props.selected ? 4 : 0};
  }
  .border {
    stroke: ${props => props.selected ? `none`: `white`};
    stroke-width:${props => props.selected ? 0 : 1};
    fill: black;
  }
  &:hover .border{
    stroke: #feec04;
  }
`

const Bolinha = ({index,size,x,y,img,title,selected,onClick}) => {
  
  const half = size * 0.5

  const variants = {
    visible: i => ({
      opacity: 1,
      transition: {
        delay: i * 0.1,
        duration: 2
      },
    }),
    hidden: { opacity: 0 },
  }

  return (
    <motion.svg
      height={size}
      width={size}
      onClick={onClick}
      custom={index}
      animate="visible"
      initial="hidden"
      variants={variants}
      style={{
        position: `absolute`,
        transform: `translate(${x-half}px,${y-half}px) scale(${selected ? 1.1 : 0.9})`
      }}
    >
      <defs>
        <pattern id={`img${index}`} patternUnits="userSpaceOnUse" width="200" height="100" patternTransform="translate(50 -5)">
          <image href={img} x="0" y="0" width="200" height="100" />
          {selected && <animateTransform
            attributeName="patternTransform"
            type="translate"
            from="0 -5"
            to="200 -5"
            begin="0s"
            dur="8s"
            repeatCount="indefinite"
          />}
        </pattern>
      </defs>
      <title>{title}</title>
      <OutCircle selected={selected}>
        <circle
          className="border"
          cx={half}
          cy={half}
          r={half * 0.98}
        />
        <circle
          className="icon"
          cx={half}
          cy={half}
          r={half * 0.9}
          fill={`url(#img${index})`}
        />
      </OutCircle>
    </motion.svg>
  )
}


export default Bolinha;