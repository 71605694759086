import React from 'react'
import styled from "styled-components"
import tw from 'tailwind.macro'
import { motion } from "framer-motion"

export const ExtLink = ({href,className,children}) => <a href={href} className={className} rel="noopener noreferrer" target="_blank">{children}</a>

export const Main = styled.main`
  ${tw`bg-black min-h-screen flex flex-col items-center justify-center text-white`};
  @media(min-width: 768px) {
    min-height: 540px;
    height: 100%;
  }
`;

export const Header = styled.header`
  ${tw`mb-4`}
`;

export const Footer = styled.footer`
  ${tw`mt-8 mb-4 uppercase text-xs font-bold`}
  img {
    ${tw`relative`}
    top: 2px;
  }
`;

export const Content = styled.div`
  ${tw`relative overflow-hidden`}
  width: ${props => `${props.size}px` };
  height: ${props => `${props.size}px` };
  @media(max-width: 768px) {
    ${tw`select-none`}
  }
`;

export const InsideContent = styled.div`
  ${tw`h-full flex flex-col items-center justify-center text-center`};
  padding: 0 ${props=>props.padding}px;
`;

export const CallToAction = styled(motion.div)`
  ${tw`uppercase text-xs`};
  color: #feec04;
  @media(min-width: 768px) {
    ${tw`text-base`};
  }
`;

export const InfoTitle = styled.div`
  ${tw`uppercase text-xl leading-none mb-1`};
  @media(min-width: 768px) {
    ${tw`text-2xl`};
  }
`;

export const InfoText = styled.div`
  ${tw`text-xs`};
  @media(min-width: 768px) {
    ${tw`text-sm`};
  }
`;

export const RoomStatus = styled(motion.div)`
  ${tw`text-xs font-bold uppercase`};
  color: #feec04;
  @media(min-width: 768px) {
    ${tw`text-sm`};
  }
`;

export const InfoLink = styled(ExtLink)`
  ${tw`text-xs font-bold uppercase mt-4 text-black px-4 py-3 border-0 rounded-sm no-underline inline-block`};
  background: #feec04;
  @media(min-width: 768px) {
    ${tw`text-sm`};
  }
`;

export const DisabledLink = styled.a`
  ${tw`text-xs font-bold uppercase mt-4 text-black px-4 py-3 border-0 rounded-sm no-underline inline-block`};
  background: transparent;
  color: #feec04;
  border: 1px solid #feec04;
  @media(min-width: 768px) {
    ${tw`text-sm`};
  }
`;

export const RoomSlots = styled.div`
  ${tw`mt-2 mb-1`};
`

export const Dot = styled.span`
  height: 6px;
  width: 6px;
  margin: 2px;
  background-color: ${props => props.available ? '#57535f' : '#feec04'};
  border-radius: 50%;
  display: inline-block;
  @media(min-width: 768px) {
    height: 8px;
    width: 8px;
    margin: 2px;
  }
`;